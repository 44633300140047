/* eslint-disable no-param-reassign */
import axios from 'axios'
// eslint-disable-next-line import/no-cycle
import store from '@/store'

// const API_URL = 'http://localhost:8000/api'
// const API_URL = 'https://e-cev-backend.mameribj.org/api'
const API_URL = 'https://ecev-api.qcdigitalhub.com/api'

const axiosClient = axios.create({
  baseURL: API_URL,
})

axiosClient.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${store.state.authStore.token}`
  config.headers.withCredentials = true
  return config
})

export default axiosClient
